<template lang="html">
  <div>
    <div class="vue-time-slot-container">
      <div class="vue-time-slot-table-row-header">
        <span class="vue-time-slot-column-header">Time</span>
        <span class="vue-time-slot-row-header" v-for="time in timeRange" :key="time">
          {{ time }}
        </span>
      </div>
      <div class="vue-time-slot-calendar-table" id="vue-time-slot-calendar-table">
        <div class="vue-time-slot-day-header" v-for="day in dayRange" :key="+day">
          <span class="vue-time-slot-column-header">
            {{ day.format('ddd,') }}
            <br>
            {{ day.format('MMM D') }}
          </span>
          <span class="vue-time-slot-cell" v-for="datetime in dayTimeRange(day)"
                :class="{'-selected': isSelected(datetime), '-booked': isBooked(datetime), }"
                :key="+datetime" @click="slot($event, datetime)"
          >
            {{ datetime.format('HH:mm') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    minuteRange: {
      type: Number,
      default: 3
    },
    value: {
      type: String,
      default: ''
    },
    minHour: {
      type: [String, Number],
      default: 10
    },
    maxHour: {
      type: [String, Number],
      default: 17
    },
    bookedSlots: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {},
  data () {
    return {
      todayDate: moment()
    }
  },
  computed: {
    dayRange () {
      return Array.from(new Array(21), (_, i) => {
        return this.todayDate.clone().add(i, 'days')
      })
    },
    timeRange () {
      return Array.from(new Array(+this.maxHour - +this.minHour), (_, i) => {
        return i + +this.minHour
      }).reduce((hours, hour) => {
        Array.from(new Array(Math.ceil(60 / +this.minuteRange)), (_, i) => {
          const minute = i * +this.minuteRange
          hours.push(`${hour <= 9 ? '0' : ''}${hour}:${minute <= 9 ? '0' : ''}${minute}`)
        })
        return hours
      }, [])
    }
  },
  mounted () {
    this.todayDate = moment()
  },
  methods: {
    dayTimeRange (day) {
      return Array.from(new Array(+this.maxHour - +this.minHour), (_, i) => {
        return i + +this.minHour
      }).reduce((hours, hour) => {
        Array.from(new Array(Math.ceil(60 / +this.minuteRange)), (_, i) => {
          const minute = i * +this.minuteRange
          hours.push(day.clone().hours(hour).minutes(minute))
        })
        return hours
      }, [])
    },
    isSelected (datetime) {
      const currentDate = moment(this.value)
      return currentDate && currentDate.isValid() ? currentDate.isSame(datetime) : false
    },
    isBooked (datetime) {
      return datetime.isValid() && ([0, 6].includes(datetime.day()) || this.bookedSlots.find(s => s(datetime)))
    },
    slot (event, datetime) {
      if (!this.isBooked(datetime)) {
        if (this.isSelected(datetime)) {
          this.$emit('input', null)
        } else {
          this.$emit('input', datetime.toISOString())
        }
      } else {
        this.$modal.show('dialog', {
          title: 'Warning',
          text: 'This time slot is already booked. Please, select another option',
          buttons: [
            {
              class: 'close',
              title: 'Close'
            },
            {
              class: 'bordered',
              title: 'Close',
              handler: () => {
                this.$modal.hide('dialog')
              }
            }
          ]
        })
      }
    }
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
  $base-font-size: 14px;
  $cell-width: 90px;
  $cell-height: 30px;
  .vue-time-slot-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 13px;
  }

  .vue-time-slot-row-header {
    border: none !important;
    text-align: center;
    cursor: pointer;
    background-color: #ccc;
    font-size: $base-font-size;
    padding: 7px;
    color: #000;
    width: 100%;
    height: $cell-height;
    box-sizing: border-box;
  }

  .vue-time-slot-column-header {
    border: none !important;
    text-align: center;
    cursor: pointer;
    background-color: #ccc;
    font-size: $base-font-size;
    height: $cell-height;
    padding: 5px 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #000;
  }

  .vue-time-slot-cell{
    padding: 7px;
    background-color: #fff;
    border-style: solid;
    border-color: #ccc;
    border-width: 1px 1px 0 0;
    text-align: center;
    cursor: pointer;
    color: #fff;
    font-size: $base-font-size;
    height: $cell-height;
    box-sizing: border-box;
    &.-selected {
      background-color: color(success);
      color: #fff;
      &:hover{
        background-color: color(success);
      }
    }
    &.-booked {
      background-color: #ccc;
      cursor: not-allowed;
      color: #fff;
      &:hover{
        background-color: #ccc;
      }
    }
    &:hover{
      background-color: color(secondary, $colors-background);
    }
    &:last-child{
      border-width: 1px 1px 1px 0;
    }
  }
  .vue-time-slot-active-cell {
    background-color: color(success);
    color: #fff;
  }
  .vue-time-slot-table-row-header {
    display: flex;
    flex-direction: column;
    width: $cell-width / 1.5;
  }
  .vue-time-slot-day-header {
    display: flex;
    flex-direction: column;
    width: $cell-width;
  }
  .vue-time-slot-calendar-table {
    background-color: transparent;
    overflow: auto;
    white-space: nowrap;
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .vue-time-slot-calendar-table .vue-time-slot-day-header:first-child{
    border-style: solid;
    border-color: #ccc;
    border-width: 0 0 0 1px;
  }

  .arrow-block {
    font-size: 25px;
    font-weight: bolder;
    color: color(success);
    cursor: pointer;
    margin: -10px 15px;
  }
</style>
