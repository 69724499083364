<template>
  <div class="default-layout">
    <overlay v-if="overlayActive" />
    <loader />
    <div id="viewport" class="w-100 relative">
      <main-header v-if="!isMainPage" />
      <sidebar-menu />
      <slot />
      <main-footer />
      <notification />
      <sign-up />
      <invite />
      <schedule-a-call />
      <referral />
      <cookie-notification />
      <v-dialog />
    </div>
    <vue-progress-bar />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import MainHeader from '../components/blocks/Header/Header.vue'
import MainFooter from '../components/blocks/Footer/Footer.vue'

import Overlay from '../components/Overlay.vue'
import SidebarMenu from '../components/blocks/SidebarMenu/SidebarMenu.vue'
import Loader from '../components/Loader.vue'
import Notification from '../components/Notification.vue'
import SignUp from '../components/blocks/Auth/SignUp.vue'
import CookieNotification from '../components/CookieNotification.vue'
import Invite from '../components/blocks/Referral/Invite'
import ScheduleACall from '../components/blocks/Referral/ScheduleACall'
import Referral from '../components/blocks/Referral/InviteFriends'

import Head from '../resource/head'

export default {
  data () {
    return {
      loadOrderConfirmation: false,
      ordersData: []
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay,
      isSearchPanelOpen: state => state.ui.searchpanel,
      isSidebarOpen: state => state.ui.sidebar,
      isMicrocartOpen: state => state.ui.microcart,
      isWishlistOpen: state => state.ui.wishlist

    }),
    isMainPage () {
      return this.$route.name === 'home' || this.$route.name === 'appointment' || this.$route.name === 'profile'
    }
  },
  methods: {
    onOrderConfirmation (payload) {
      this.loadOrderConfirmation = true
      this.ordersData = payload
    }
  },
  beforeMount () {
    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      this.$Progress.increase(40)
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
  },
  beforeDestroy () {
  },
  metaInfo: Head,
  components: {
    MainHeader,
    MainFooter,
    Referral,
    Overlay,
    Loader,
    SidebarMenu,
    Notification,
    SignUp,
    CookieNotification,
    Invite,
    ScheduleACall
  }
}
</script>
<style type="text/scss" lang="scss">
  $primary-text-color: color(primary);
  $background-color: color(primary, $colors-background);
  $button-border: color(primary, $colors-border);
  $border-color-dark: color(primary, $colors-border, hover);
  $button-hover: color(primary, $colors-border, hover);
  $accent-color: color(accent);
  $primary-text-light: color(secondary);
  //colors
  $color_alto: #ddd;
  $color_picton_blue: #369be9;
  $black_20: rgba(0, 0, 0, 0.2);
  $white: white;
  $color_big_stone_40: rgba(27, 33, 58, 0.4);
  $color_gallery: #eee;
  $black_1: rgba(0, 0, 0, 0.01);
  $black_2_5: rgba(0, 0, 0, 0.025);

  .vue-modal-resizer {
    display: block;
    overflow: hidden;
    position: absolute;
    width: 12px;
    height: 12px;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background: transparent;
    cursor: se-resize;
    &.clicked::after {
      border-bottom: 10px solid $color_picton_blue;
    }
  }
  .vue-modal-resizer::after {
    display: block;
    position: absolute;
    content: '';
    background: transparent;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-bottom: 10px solid $color_alto;
    border-left: 10px solid transparent;
  }
  .v--modal-block-scroll {
    overflow: hidden;
    width: 100vw;
  }
  .v--modal-overlay {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: $black_20;
    z-index: 999;
    opacity: 1;
    &.scrollable {
      height: 100%;
      min-height: 100vh;
      overflow-y: auto;
      .v--modal-box {
        margin-bottom: 2px;
      }
    }
    .v--modal-background-click {
      width: 100%;
      height: 100%;
    }
    .v--modal-box {
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
    }
  }
  .v--modal {
    background-color: $white;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0 20px 60px -2px $color_big_stone_40;
    &.v--modal-fullscreen {
      width: 100vw;
      height: 100vh;
      margin: 0;
      left: 0;
      top: 0;
    }
    @include smaller-than(tablet) {
      left: 0 !important;
      top: 0 !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
  .v--modal-top-right {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  .overlay-fade-enter-active {
    transition: all 0.2s;
  }
  .overlay-fade-leave-active {
    transition: all 0.2s;
    opacity: 0;
  }
  .overlay-fade-enter {
    opacity: 0;
  }
  .nice-modal-fade-enter-active {
    transition: all 0.4s;
  }
  .nice-modal-fade-leave-active {
    transition: all 0.4s;
    opacity: 0;
    transform: translateY(-20px);
  }
  .nice-modal-fade-enter {
    opacity: 0;
    transform: translateY(-20px);
  }
  .vue-dialog {
    div {
      box-sizing: border-box;
    }
    .dialog-flex {
      width: 100%;
      height: 100%;
    }
    .dialog-content {
      flex: 1 0 auto;
      width: 100%;
      padding: 15px;
      font-size: 14px;
    }
    .dialog-c-title {
      padding-bottom: 15px;
    }
    .dialog-c-text {
    }
    .vue-dialog-buttons {
      display: flex;
      flex: 0 1 auto;
      width: 100%;
    }
    .vue-dialog-buttons-none {
      width: 100%;
      padding-bottom: 15px;
    }
  }
  .vue-dialog-button {
    font-size: 12px !important;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    box-sizing: border-box;
    line-height: 40px;
    height: 40px;
    color: inherit;
    font: inherit;
    outline: none;
    &:hover {
      background: $black_1;
    }
    &:active {
      background: $black_2_5;
    }
    &:not(:first-of-type) {
      border-left: 1px solid $color_gallery;
    }
  }
  .vue-dialog {
    position: relative;
    padding: 40px 20px 20px !important;
    border-radius: 0;
    background-color: color(white);
    box-shadow: 0 13px 35px 0 color(black);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    %dialog-button {
      font-size: 12px !important;
      cursor: pointer;
      box-sizing: border-box;
      @extend %button-mixin;
      @extend %button-bordered-mixin;
      @extend %button-big-mixin;
      color: $primary-text-color;
      &:hover {
        //background: rgba(0, 0, 0, 0.01);
      }
      &:active {
        //background: rgba(0, 0, 0, 0.025);
      }
      &:not(:first-of-type):not(.-half) {
        margin-left: 10px;
        border: 1px solid $button-border;
      }
      &.accent {
        @extend %button-accent-mixin;
        border: none;
      }
      &.close {
        @extend %reset-Button;
        background-size: cover;
        position: absolute;
        right: 20px;
        top: 20px;
        width: 16px;
        height: 17px;
        cursor: pointer;
        color: transparent;
        border-width: 0 !important;
        background-image: url('../assets/close_big.svg');
        &:not(:first-of-type) {
          border-left: none;
          margin: 0;
        }
      }
      // add -half class to button if it's single (not counting .close button)
      &.-half {
        max-width: 50%;
      }
    }
    div {
      box-sizing: border-box;
    }
    .dialog-flex {
      width: 100%;
      height: 100%;
    }
    .dialog-content {
      flex: 1 0 auto;
      width: 100%;
      padding: 0;
      font-size: 16px;
      box-sizing: border-box;
      &.-spread {
        display: flex;
        flex-direction: column;
      }
      &.content_single {
        text-align: center;
        display: flex;
        align-content: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .dialog-c-title {
      font-size: 24px;
      font-weight: 400;
      color: $primary-text-color;
      line-height: 1;
      text-align: center;
      margin-bottom: 10px;
      width: 100%;
      + .content_single {
        margin-bottom: 10px;
      }
      &.-small {
        font-size: 18px;
        line-height: 25px;
      }
    }
    .dialog-c-text {
      margin: 20px 0;
      text-align: center;
      padding: 0 10px;
    }
    .dialog__text {
      font-size: 16px;
    }
    .vue-dialog-buttons {
      display: flex;
      flex: 0 1 auto;
      width: 100%;
      border-top: none;
      justify-content: center;
      margin-top: 20px;
      button {
        @extend %dialog-button;
      }
    }
    .vue-dialog-buttons-none {
      width: 100%;
      padding-bottom: 15px;
    }
    &-button {
      @extend %dialog-button;
    }
  }
  .v--modal-box {
    padding: 40px 20px 20px !important;;
    border-radius: 0;
    background-color: $background-color;
    box-shadow: 0 13px 35px 0 $border-color-dark;
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    font-weight: 300;
  }

  .modal-window {
    &.v--modal-box {
      @extend .v--modal-box;
    }
    &__caption {
      font-size: 26px;
      font-weight: 400;
      color: darken($primary-text-color, 5%);
      line-height: 1;
      text-align: center;
      margin-bottom: 30px;
      width: 100%;
      cursor: default;
      &.-draggable {
        cursor: grab;
        user-select: none;
      }
      + .content_single {
        margin-bottom: 10px;
      }
      &.-small {
        font-size: 18px;
        line-height: 25px;
      }
    }
    &__close-button {
      background-size: cover;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 16px;
      height: 17px;
      cursor: pointer;
      color: $primary-text-light;
      background-image: url('../assets/close_big.svg');
    }
    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      overflow: auto;
      box-sizing: border-box;
      padding: 10px 0;
      margin-bottom: 20px;
    }
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 100%;
      text-align: center;
      margin: 10px 0;
      min-height: 20px;
      &.-stretch {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-flex-shrink: 3;
        -ms-flex-negative: 3;
        flex-shrink: 1;
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
      }
      &.-big-text {
        font-size: 16px;
        line-height: 25px;
      }
      &.button-row {
        margin-bottom: 0;
      }
    }
    &__button {
      cursor: pointer;
      font-size: 10px;
      color: $background-color;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1;
      text-align: center;
      border-radius: 5px;
      background-color: $accent-color;
      width: 100%;
      height: 52px;
      padding: 20px 0;
      user-select: none;
      &.-small {
        width: auto;
        padding: 20px;
        min-width: 200px;
      }
      &[disabled] {
        cursor: default;
        background-color: lighten($accent-color, 10%);
      }
      &.-bordered {
        @extend %button-bordered-mixin;
      }
    }
    &__content {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -webkit-flex-shrink: 3;
      -ms-flex-negative: 3;
      flex-shrink: 1;
      -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      width: 100%;
      box-sizing: border-box;
      &.-spread{
        display: flex;
        flex-direction: column;
      }
      &.content_single {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
      }
    }
    &__actions {
      width: 100%;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      .button:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  .modal__tabs {
    width: 100%;
    max-height: 50px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    .tabs {
      .tabs__tab {
        cursor: pointer;
      }
    }
  }
</style>
