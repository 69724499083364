<template>
  <div>
    <header class="modal-header py25 px65 h1 serif weight-400 bg-cl-secondary">
      <i
        slot="close"
        class="modal-close material-icons p15 cl-bg-tertiary"
        @click="close"
      >
        close
      </i>
      Reset password
    </header>

    <div class="modal-content pt30 pb60 px65 cl-secondary">
      <template v-if="!passwordSent">
        <form @submit.prevent="sendEmail" novalidate>
          <div class="mb35">
            <p class="mb45">
              Enter your email to receive instructions on how to reset your password.
            </p>
            <base-input
              type="email"
              name="email"
              v-model="email"
              focus
              :placeholder="'E-mail address *'"
              :validations="[
                {
                  condition: !$v.email.required && $v.email.$error,
                  text: 'Field is required.'
                },
                {
                  condition: !$v.email.email && $v.email.$error,
                  text: 'Please provide valid e-mail address.'
                }
              ]"
            />
          </div>
          <button-full class="mb35" type="submit">
            Reset password
          </button-full>
          <div class="center-xs">
            or
            <a href="#" @click.prevent="switchElem">
              return to log in
            </a>
          </div>
        </form>
      </template>
      <template v-if="passwordSent">
        <form class="py20">
          <p class="py30 mb80">
            We've sent password reset instructions to your email. Check your inbox and follow the link.
          </p>
          <button-full class="mb35" type="submit">
            Back to login
          </button-full>
        </form>
      </template>
    </div>
  </div>
</template>

<script>

import { required, email } from 'vuelidate/lib/validators'

export default {
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    close () {
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    sendEmail () {
      // todo: send email with reset password instructions

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: 'Please fix the validation errors',
          action1: { label: 'OK' }
        })
        return
      }

      this.$bus.$emit('notification-progress-start', 'Resetting the password ... ')
      this.$store.dispatch('user/resetPassword', { email: this.email }).then((response) => {
        this.$bus.$emit('notification-progress-stop')
        if (response.code === 200) {
          this.passwordSent = true
        } else {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: response.result || 'Error while sending reset password e-mail',
            action1: { label: 'OK', action: 'close' }
          })
        }
      }).catch((err) => {
        console.error(err.response)
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: err.response.data.result || 'Error while sending reset password e-mail',
          action1: { label: 'OK', action: 'close' }
        })
        this.$bus.$emit('notification-progress-stop')
      })
    },
    switchElem () {
      this.$store.commit('ui/setAuthElem', 'login')
    }
  },
  name: 'ForgotPass',
  data () {
    return {
      email: '',
      passwordSent: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-content {
    @media (max-width: 400px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
</style>
