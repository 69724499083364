<template>
  <modal name="invite" :width="620" height="auto">
    <div class="modal-window__close-button" @click="close" />
    <div class="modal-window__caption -draggable">
      Design Consultation Request
    </div>

    <div class="modal-window__content">
      <form @submit.prevent="invite" novalidate>
        <base-input
          class="mb35"
          type="email"
          name="email"
          autocomplete="email"
          v-model="email"
          @blur="$v.email.$touch()"
          focus
          placeholder="E-mail address *"
          :validations="[
            {
              condition: !$v.email.required && $v.email.$error,
              text: 'Field is required.'
            },
            {
              condition: !$v.email.email && $v.email.$error,
              text: 'Please provide valid e-mail address.'
            }
          ]"
        />
        <div class="row mb35 no-side-margins">
          <base-input
            class="col-xs-6"
            type="text"
            name="fist-name"
            autocomplete="given-name"
            v-model="firstName"
            @blur="$v.firstName.$touch()"
            placeholder="First name *"
            :validation="{
              condition: !$v.firstName.required && $v.firstName.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-6"
            type="text"
            name="last-name"
            autocomplete="last-name"
            v-model="lastName"
            @blur="$v.lastName.$touch()"
            placeholder="Last name *"
            :validation="{
              condition: !$v.lastName.required && $v.lastName.$error,
              text: 'Field is required.'
            }"
          />
        </div>
        <base-input
          class="mb35"
          type="text"
          name="purpose"
          ref="purpose"
          v-model="purpose"
          @blur="$v.purpose.$touch()"
          placeholder="What do you want designed? *"
          :validations="[
            {
              condition: !$v.purpose.required && $v.purpose.$error,
              text: 'Field is required.'
            }
          ]"
        />
        <base-input
          class="mb35"
          type="text"
          name="phone"
          ref="phone"
          v-model="phone"
          placeholder="Phone *"
          @blur="$v.phone.$touch()"
          :validations="[
            {
              condition: !$v.phone.required && $v.phone.$error,
              text: 'Field is required.'
            },
            {
              condition: !$v.phone.phone && $v.phone.$error,
              text: 'Wrong phone format'
            }
          ]"
        />
        <button-full class="mb20" type="submit">
          Get A Free Consultation
        </button-full>
      </form>
    </div>

    <div class="modal-window__actions" />
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import { email } from 'vuelidate/lib/validators'
import { phone, required } from '../../../utils/validations'
import { trackEvent } from '../../../utils/analytics'

export default {
  name: 'Invite',
  validations: {
    email: {
      required,
      email
    },
    firstName: {
      required
    },
    lastName: {
      required
    },
    purpose: {
      required
    },
    phone: {
      required,
      phone
    }
  },
  data () {
    return {
      email: '',
      firstName: '',
      lastName: '',
      purpose: '',
      phone: ''
    }
  },
  computed: {
    ...mapState({
      activeElem: state => state.ui.authElem
    })
  },
  methods: {
    invite () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        trackEvent('user-action', 'invite-button-validation-fail')
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: 'Please fix the validation errors',
          action1: { label: 'Ok' }
        })
        return
      }
      this.callInvite()
    },
    onSuccess () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: 'Invitation has been sent!',
        action1: { label: 'Ok' }
      })
      trackEvent('user-action', 'invite-button-success')
      this.handleInviteFinish()
    },
    onAlreadyInvited () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: 'You are already invited. We will get in contact with you soon',
        action1: { label: 'Ok' }
      })
      trackEvent('user-action', 'invite-button-already-invited')
      this.handleInviteFinish()
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: result.result,
        action1: { label: 'Ok' }
      })
      trackEvent('user-action', 'invite-button-fail')
    },
    handleInviteFinish () {
      this.$store.dispatch('referral/setInviteState', this.email)
      this.close()
      this.$modal.show('dialog', {
        title: 'Consultation',
        text: 'How do you want to have your design consultation?',
        buttons: [
          {
            title: 'Phone call',
            class: 'accent',
            handler: () => {
              trackEvent('user-action', 'select-consultation-phone')
              this.$modal.hide('dialog')
              this.$modal.show('schedule-call')
            }
          },
          {
            class: 'close',
            title: 'Close'
          },
          {
            class: 'bordered',
            title: 'Online',
            handler: () => {
              trackEvent('user-action', 'select-consultation-online')
              this.$modal.hide('dialog')
              this.$router.push('/consultation/checklist')
            }
          }
        ]
      })
    },
    close () {
      // TODO Move to theme
      this.$modal.hide('invite')
    },
    callInvite () {
      // TODO Move to theme
      this.$bus.$emit('notification-progress-start', 'Requesting invite ...')
      trackEvent('user-action', 'invite-button-send')
      this.$store.dispatch('referral/invite', { email: this.email, purpose: this.purpose, phone: this.phone, firstname: this.firstName, lastname: this.lastName }).then((result) => {
        console.debug(result)
        // TODO Move to theme
        this.$bus.$emit('notification-progress-stop')
        if (!result._id) {
          if (result === 'Already Invited') {
            this.onAlreadyInvited()
            this.close()
          } else {
            this.onFailure(result)
          }
        } else {
          this.onSuccess()
          this.close()
        }
      }).catch(err => {
        // TODO Move to theme
        this.$bus.$emit('notification-progress-stop')
        console.error(err)
      })
    }
  }
}
</script>

<style scoped>
  .modal {
    font-size: 18px;
  }
</style>
