<template>
  <div class="inline-flex relative" @click="onClick">
    <button
      type="button"
      class="bg-cl-transparent brdr-none p0 fs-medium-small"
    >
      Create account
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    onClick () {
      this.$store.commit('ui/setAuthElem', 'register')
      this.$bus.$emit('modal-show', 'modal-signup')
    }
  }
}
</script>
