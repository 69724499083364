import { helpers } from 'vuelidate/lib/validators'

export const name = value => {
  return helpers.regex('name', /^[a-zA-Z+]*$/)(value)
}

export const required = value => {
  return helpers.req(value.trim())
}

export const isTrue = value => {
  console.log(value)
  return Boolean(value)
}

export const phone = value => {
  return helpers.regex('phone', /^[\w\d-)(+ ]*$/i)(value.trim())
}
