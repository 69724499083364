var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"modal-header py25 px65 h1 serif weight-400 bg-cl-secondary"},[_c('i',{staticClass:"modal-close material-icons p15 cl-bg-tertiary",attrs:{"slot":"close"},on:{"click":_vm.close},slot:"close"},[_vm._v("\n      close\n    ")]),_vm._v("\n    Log in\n  ")]),_c('div',{staticClass:"modal-content pt30 pb60 px65 cl-secondary"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('base-input',{staticClass:"mb35",attrs:{"type":"email","name":"email","focus":"","placeholder":'E-mail address *',"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: 'Field is required.'
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: 'Please provide valid e-mail address.'
          }
        ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('base-input',{staticClass:"mb35",attrs:{"type":"password","name":"password","placeholder":'Password *',"validation":{
          condition: !_vm.$v.password.required && _vm.$v.password.$error,
          text: 'Field is required.'
        }},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"row"},[_c('base-checkbox',{staticClass:"col-xs-7 col-sm-6 mb35",attrs:{"id":"remember"},on:{"click":function($event){_vm.remember = !_vm.remember}},model:{value:(_vm.remember),callback:function ($$v) {_vm.remember=$$v},expression:"remember"}},[_vm._v("\n          Remember me\n        ")]),_c('div',{staticClass:"col-xs-5 col-sm-6 mb35 flex end-xs middle-xs"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remindPassword.apply(null, arguments)}}},[_vm._v("\n            Forgot the password?\n          ")])])],1),_c('button-full',{staticClass:"mb20",attrs:{"type":"submit","data-testid":"loginSubmit"}},[_vm._v("\n        Log in to your account\n      ")]),_c('div',{staticClass:"center-xs"},[_vm._v("\n        or\n        "),_c('a',{attrs:{"href":"#","data-testid":"registerLink"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem.apply(null, arguments)}}},[_vm._v("\n          register an account\n        ")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }