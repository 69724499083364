var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"modal-header py25 px65 h1 serif weight-400 bg-cl-secondary"},[_c('i',{staticClass:"modal-close material-icons p15 cl-bg-tertiary",attrs:{"slot":"close"},on:{"click":_vm.close},slot:"close"},[_vm._v("\n      close\n    ")]),_vm._v("\n    Reset password\n  ")]),_c('div',{staticClass:"modal-content pt30 pb60 px65 cl-secondary"},[(!_vm.passwordSent)?[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_c('div',{staticClass:"mb35"},[_c('p',{staticClass:"mb45"},[_vm._v("\n            Enter your email to receive instructions on how to reset your password.\n          ")]),_c('base-input',{attrs:{"type":"email","name":"email","focus":"","placeholder":'E-mail address *',"validations":[
              {
                condition: !_vm.$v.email.required && _vm.$v.email.$error,
                text: 'Field is required.'
              },
              {
                condition: !_vm.$v.email.email && _vm.$v.email.$error,
                text: 'Please provide valid e-mail address.'
              }
            ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('button-full',{staticClass:"mb35",attrs:{"type":"submit"}},[_vm._v("\n          Reset password\n        ")]),_c('div',{staticClass:"center-xs"},[_vm._v("\n          or\n          "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem.apply(null, arguments)}}},[_vm._v("\n            return to log in\n          ")])])],1)]:_vm._e(),(_vm.passwordSent)?[_c('form',{staticClass:"py20"},[_c('p',{staticClass:"py30 mb80"},[_vm._v("\n          We've sent password reset instructions to your email. Check your inbox and follow the link.\n        ")]),_c('button-full',{staticClass:"mb35",attrs:{"type":"submit"}},[_vm._v("\n          Back to login\n        ")])],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }