<template>
  <div class="sidebar-menu fixed mw-100 bg-cl-secondary" :class="{ active: showMenu }">
    <div class="row brdr-bottom-1 brdr-cl-bg-secondary">
      <div class="col-xs bg-cl-primary" v-if="submenu.depth">
        <sub-btn type="back" class="bg-cl-transparent brdr-none" />
      </div>
      <div class="col-xs bg-cl-primary">
        <button
          type="button"
          :aria-label="'Close'"
          class="w-100 inline-flex end-xs bg-cl-transparent brdr-none p0 close-btn"
          @click="closeMenu"
        >
          <i class="material-icons p15">close</i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 h4 serif">
        <ul class="p0 m0 relative sidebar-menu__list" :style="mainListStyles">
          <li @click="closeMenu" class="brdr-bottom-1 brdr-cl-bg-secondary bg-cl-primary">
            <router-link
              class="block px25 py20 cl-accent no-underline"
              :to="'/'"
              exact
            >
              Home
            </router-link>
          </li>
          <li
            class="brdr-bottom-1 brdr-cl-bg-secondary bg-cl-primary flex"
            :key="category.slug"
            @click="closeMenu"
            v-for="category in visibleCategories"
          >
            <div v-if="isCurrentMenuShowed" class="subcategory-item">
              <sub-btn
                class="bg-cl-transparent brdr-none fs-medium"
                :id="category.id"
                :name="category.name"
                v-if="category.children_count > 0"
              />
              <router-link
                v-else
                class="px25 py20 cl-accent no-underline col-xs"
                :to="{ name: 'category', params: { id: category.id, slug: category.slug }}"
              >
                {{ category.name }}
              </router-link>
            </div>

            <sub-category
              :category-links="category.children_data"
              :id="category.id"
              :parent-slug="category.slug"
            />
          </li>
          <li @click="closeMenu" v-if="isCurrentMenuShowed" class="bg-cl-secondary">
            <router-link
              class="block px25 py20 brdr-bottom-1 brdr-cl-secondary cl-accent no-underline fs-medium-small"
              :to="'/faq'"
              exact
            >
              FAQ
            </router-link>
          </li>
          <li @click="closeMenu" v-if="isCurrentMenuShowed" class="bg-cl-secondary">
            <router-link
              class="block px25 py20 brdr-bottom-1 brdr-cl-secondary cl-accent no-underline fs-medium-small"
              :to="'/about'"
              exact
            >
              Our Mission
            </router-link>
          </li>
          <li @click="closeMenu" v-if="isCurrentMenuShowed" class="bg-cl-secondary">
            <router-link
              class="block px25 py20 brdr-bottom-1 brdr-cl-secondary cl-accent no-underline fs-medium-small"
              :to="'/technology'"
              exact
            >
              Technology
            </router-link>
          </li>
          <li @click="closeMenu" v-if="isCurrentMenuShowed" class="bg-cl-secondary">
            <router-link
              class="block px25 py20 brdr-bottom-1 brdr-cl-secondary cl-accent no-underline fs-medium-small"
              :to="'/privacy'"
              exact
            >
              Privacy policy
            </router-link>
          </li>
          <li @click="closeMenu" v-if="isCurrentMenuShowed" class="bg-cl-secondary">
            <router-link
              class="block px25 py20 brdr-cl-secondary cl-white-smoke no-underline fs-medium-small"
              style="color: #f2f2f2;"
              :to="'/questionnaire'"
              exact
            >
              Questionnaire
            </router-link>
          </li>
          <!--<li @click="closeMenu" class="brdr-bottom-1 brdr-cl-secondary bg-cl-secondary flex">
            <sub-btn
              v-if="currentUser"
              :name="'My account'"
              class="bg-cl-transparent brdr-none fs-medium-small"
            />
            <sub-category
              v-if="currentUser"
              :my-account-links="myAccountLinks"
              :id="'foo'"
            />
            <a
              v-if="!currentUser && isCurrentMenuShowed"
              href="#"
              @click.prevent="login"
              class="block w-100 px25 py20 cl-accent no-underline fs-medium-small"
            >
              My account
            </a>
          </li>-->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SubBtn from './SubBtn'
import SubCategory from './SubCategory'

export default {
  components: {
    SubCategory,
    SubBtn
  },
  data () {
    return {
      myAccountLinks: [
        {
          id: 1,
          name: 'My profile',
          url: '/my-account'
        },
        {
          id: 2,
          name: 'My shipping details',
          url: '/my-account/shipping-details'
        },
        {
          id: 3,
          name: 'My newsletter',
          url: '/my-account/newsletter'
        },
        {
          id: 4,
          name: 'My orders',
          url: '/my-account/orders'
        },
        {
          id: 5,
          name: 'My loyalty card',
          url: '#'
        },
        {
          id: 6,
          name: 'My product reviews',
          url: '#'
        }
      ],
      componentLoaded: false
    }
  },
  computed: {
    ...mapGetters('category', ['getCategories']),
    categories () {
      return []/* this.getCategories.filter((op) => {
        return op.level === (this.$store.state.config.entities.category.categoriesDynamicPrefetchLevel ? this.$store.state.config.entities.category.categoriesDynamicPrefetchLevel : 2) // display only the root level (level =1 => Default Category), categoriesDynamicPrefetchLevel = 2 by default
      }) */
    },
    ...mapState({
      isOpen: state => state.ui.sidebar
    }),
    compareIsActive () {
      // Computed property renamed to 'isEmpty'
      return !this.isEmpty
    },
    mainListStyles () {
      return this.submenu.depth ? `transform: translateX(${this.submenu.depth * 100}%)` : false
    },
    ...mapState({
      submenu: state => state.ui.submenu,
      currentUser: state => state.user ? state.user.current : null
    }),
    getSubmenu () {
      return this.submenu
    },
    visibleCategories () {
      return this.categories.filter(category => {
        return category.product_count > 0 || category.children_count > 0
      })
    },
    isCurrentMenuShowed () {
      return !this.getSubmenu || !this.getSubmenu.depth
    },
    showMenu () {
      return this.isOpen
    },
    isEmpty () {
      return this.$store.getters['compare/isEmpty']
    }
  },
  mounted () {
    const keydownHandler = (e) => {
      // for old browser support as a fallback
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.onEscapePress()
      }
    }
    document.addEventListener('keydown', keydownHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', keydownHandler)
    })
    this.$nextTick(() => {
      this.componentLoaded = true
    })
  },
  methods: {
    login () {
      this.$bus.$emit('modal-show', 'modal-signup')
      this.$router.push({ name: 'my-account' })
    },
    onEscapePress () {
      this.closeMenu()
    },
    closeMenu () {
      this.$store.commit('ui/setSidebar', false)
      this.$store.commit('ui/setMicrocart', false)
    }
  },
  name: 'SidebarMenu'
}
</script>

<style lang="scss">
@import "../../../css/animations/transitions";
$bg-secondary: color(secondary, $colors-background);
$color-gainsboro: color(gainsboro);
$color-matterhorn: color(matterhorn);
$color-mine-shaft: color(mine-shaft);

.sidebar-menu {
  height: 100vh;
  width: 350px;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  transform: translateX(-100%);
  z-index: 3;
  transition: transform $duration-main $motion-main;

  @media (max-width: 767px) {
    width: 100vh;
  }

  &.active {
    transform: translateX(0);
  }

  &__list {
    transition: transform $duration-main $motion-main;
  }

  ul {
    list-style-type: none;
  }

  li {
    &:hover,
    &:focus {
      background-color: $color-gainsboro;
    }
    &.bg-cl-primary {
      &:hover,
      &:focus {
        background-color: $bg-secondary;
      }
    }
    a {
      color: $color-mine-shaft;
    }
  }

  .subcategory-item {
    display: flex;
    width: 100%;
  }

  button {
    color: $color-mine-shaft;a {
      color: $color-mine-shaft;
    }
  }

  .close-btn {
    i {
      color: $color-gainsboro;
    }
    &:hover,
    &:focus {
      i {
        color: $color-matterhorn;
      }
    }
  }

}
</style>
