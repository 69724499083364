<template>
  <div>
    <header class="modal-header py25 px65 h1 serif weight-400 bg-cl-secondary">
      <i
        slot="close"
        class="modal-close material-icons p15 cl-bg-tertiary"
        @click="close"
      >
        close
      </i>
      Create Account
    </header>

    <div class="modal-content pt30 pb60 px65 cl-secondary">
      <form @submit.prevent="register" novalidate>
        <base-input
          class="mb35"
          type="email"
          name="email"
          autocomplete="email"
          v-model="email"
          @blur="$v.email.$touch()"
          focus
          :placeholder="'E-mail address *'"
          :validations="[
            {
              condition: !$v.email.required && $v.email.$error,
              text: 'Field is required.'
            },
            {
              condition: !$v.email.email && $v.email.$error,
              text: 'Please provide valid e-mail address.'
            }
          ]"
        />
        <div class="row mb35">
          <base-input
            class="col-xs-6"
            type="text"
            name="fist-name"
            autocomplete="given-name"
            v-model="firstName"
            @blur="$v.firstName.$touch()"
            :placeholder="'First name *'"
            :validation="{
              condition: !$v.firstName.required && $v.firstName.$error,
              text: 'Field is required.'
            }"
          />
          <base-input
            class="col-xs-6"
            type="text"
            name="last-name"
            autocomplete="last-name"
            v-model="lastName"
            @blur="$v.lastName.$touch()"
            :placeholder="'Last name *'"
            :validation="{
              condition: !$v.lastName.required && $v.lastName.$error,
              text: 'Field is required.'
            }"
          />
        </div>
        <base-input
          class="mb35"
          type="password"
          name="password"
          ref="password"
          autocomplete="new-password"
          v-model="password"
          @blur="$v.password.$touch()"
          :placeholder="'Password *'"
          :validations="[
            {
              condition: !$v.password.required && $v.password.$error,
              text: 'Field is required.'
            },
            {
              condition: !$v.password.minLength && $v.password.$error,
              text: 'Password must have at least 8 letters.'
            }
          ]"
        />
        <base-input
          class="mb35"
          type="password"
          name="password-confirm"
          autocomplete="new-password"
          v-model="rPassword"
          @blur="$v.rPassword.$touch()"
          :placeholder="'Repeat password *'"
          :validations="[
            {
              condition: !$v.rPassword.required && $v.rPassword.$error,
              text: 'Field is required.'
            },
            {
              condition: !$v.rPassword.sameAsPassword && $v.rPassword.$error,
              text: 'Passwords must be identical.'
            }
          ]"
        />
        <base-checkbox
          class="mb35"
          id="terms"
          v-model="conditions"
          @click="conditions = !conditions"
          @blur="$v.conditions.$reset()"
          @change="$v.conditions.$touch()"
          :validation="{
            condition: (!$v.conditions.required || !$v.conditions.checked) && $v.conditions.$error,
            text: 'You must accept the terms and conditions.'
          }"
        >
          I accept terms and conditions *
        </base-checkbox>
        <button-full class="mb20" type="submit" :disabled="$v.$anyError">
          Register an account
        </button-full>
        <div class="center-xs">
          <span>
            or
            <a href="#" @click.prevent="switchElem">
              login to your account
            </a>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { isTrue } from '../../../utils/validations'
import Cookie from 'js-cookie'

export default {
  name: 'Register',
  data () {
    return {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      rPassword: '',
      conditions: false
    }
  },
  mounted () {
    this.$v.conditions.$touch()
  },
  validations: {
    email: {
      required,
      email
    },
    firstName: {
      required
    },
    lastName: {
      required
    },
    password: {
      minLength: minLength(8),
      required
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    },
    conditions: {
      required,
      checked: isTrue
    }
  },
  methods: {
    switchElem () {
      // TODO Move to theme
      this.$store.commit('ui/setAuthElem', 'login')
    },
    close () {
      // TODO Move to theme
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    callRegister () {
      // TODO Move to theme
      this.$bus.$emit('notification-progress-start', 'Registering the account ...')
      this.$store.dispatch('user/register', { email: this.email, password: this.password, firstname: this.firstName, lastname: this.lastName }).then((result) => {
        console.debug(result)
        // TODO Move to theme
        this.$bus.$emit('notification-progress-stop')
        if (result.code !== 200) {
          this.onFailure(result)
          // If error includes a word 'password', focus on a corresponding field
          if (result.result.includes('password')) {
            this.$refs['password'].setFocus('password')
            this.password = ''
            this.rPassword = ''
          }
        } else {
          this.onSuccess()
          this.close()
        }
      }).catch(err => {
        // TODO Move to theme
        this.$bus.$emit('notification-progress-stop')
        console.error(err)
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          timeToLive: 10000,
          message: err.response.data.result || 'Error while creating account',
          action1: { label: 'OK', action: 'close' }
        })
      })
    },
    register () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: 'Please fix the validation errors',
          action1: { label: 'OK' }
        })
        return
      }
      this.callRegister()
    },
    onSuccess () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: 'Confirmation has been sent to your email',
        action1: { label: 'OK' }
      })
      Cookie.set('page-before-register', this.$route.path)
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: result.result,
        action1: { label: 'OK' }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-content {
    @media (max-width: 400px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
</style>
