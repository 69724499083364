var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"modal-header py25 px65 h1 serif weight-400 bg-cl-secondary"},[_c('i',{staticClass:"modal-close material-icons p15 cl-bg-tertiary",attrs:{"slot":"close"},on:{"click":_vm.close},slot:"close"},[_vm._v("\n      close\n    ")]),_vm._v("\n    Create Account\n  ")]),_c('div',{staticClass:"modal-content pt30 pb60 px65 cl-secondary"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('base-input',{staticClass:"mb35",attrs:{"type":"email","name":"email","autocomplete":"email","focus":"","placeholder":'E-mail address *',"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: 'Field is required.'
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: 'Please provide valid e-mail address.'
          }
        ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"row mb35"},[_c('base-input',{staticClass:"col-xs-6",attrs:{"type":"text","name":"fist-name","autocomplete":"given-name","placeholder":'First name *',"validation":{
            condition: !_vm.$v.firstName.required && _vm.$v.firstName.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.firstName.$touch()}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('base-input',{staticClass:"col-xs-6",attrs:{"type":"text","name":"last-name","autocomplete":"last-name","placeholder":'Last name *',"validation":{
            condition: !_vm.$v.lastName.required && _vm.$v.lastName.$error,
            text: 'Field is required.'
          }},on:{"blur":function($event){return _vm.$v.lastName.$touch()}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('base-input',{ref:"password",staticClass:"mb35",attrs:{"type":"password","name":"password","autocomplete":"new-password","placeholder":'Password *',"validations":[
          {
            condition: !_vm.$v.password.required && _vm.$v.password.$error,
            text: 'Field is required.'
          },
          {
            condition: !_vm.$v.password.minLength && _vm.$v.password.$error,
            text: 'Password must have at least 8 letters.'
          }
        ]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('base-input',{staticClass:"mb35",attrs:{"type":"password","name":"password-confirm","autocomplete":"new-password","placeholder":'Repeat password *',"validations":[
          {
            condition: !_vm.$v.rPassword.required && _vm.$v.rPassword.$error,
            text: 'Field is required.'
          },
          {
            condition: !_vm.$v.rPassword.sameAsPassword && _vm.$v.rPassword.$error,
            text: 'Passwords must be identical.'
          }
        ]},on:{"blur":function($event){return _vm.$v.rPassword.$touch()}},model:{value:(_vm.rPassword),callback:function ($$v) {_vm.rPassword=$$v},expression:"rPassword"}}),_c('base-checkbox',{staticClass:"mb35",attrs:{"id":"terms","validation":{
          condition: (!_vm.$v.conditions.required || !_vm.$v.conditions.checked) && _vm.$v.conditions.$error,
          text: 'You must accept the terms and conditions.'
        }},on:{"click":function($event){_vm.conditions = !_vm.conditions},"blur":function($event){return _vm.$v.conditions.$reset()},"change":function($event){return _vm.$v.conditions.$touch()}},model:{value:(_vm.conditions),callback:function ($$v) {_vm.conditions=$$v},expression:"conditions"}},[_vm._v("\n        I accept terms and conditions *\n      ")]),_c('button-full',{staticClass:"mb20",attrs:{"type":"submit","disabled":_vm.$v.$anyError}},[_vm._v("\n        Register an account\n      ")]),_c('div',{staticClass:"center-xs"},[_c('span',[_vm._v("\n          or\n          "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem.apply(null, arguments)}}},[_vm._v("\n            login to your account\n          ")])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }