<template>
  <modal name="modal-referral" :width="620">
    <div>
      <header class="modal-header py25 px65 h2 serif weight-400 bg-cl-secondary">
        <i
          slot="close"
          class="modal-close material-icons p15 cl-bg-tertiary"
          @click="close"
        >
          close
        </i>
        Invite Friends, Earn Cash!
        <div class="fs-medium">
          Send a $5 credit to your friends, and earn even more for yourself when they join!
        </div>
      </header>

      <div class="modal-content pt30 pb60 px65 cl-secondary">
        <div class="referral-settings">
          <div>
            <div v-if="info" class="referral-settings__info">
              You are currently invited <span>{{ currentReferrals }} friend{{ currentReferrals % 10 === 1 && currentReferrals !== 11 ? '' : 's' }}!</span>
            </div>
            <div class="slider">
              <div class="slider__base">
                <div class="slider__progress" :style="{width: progressPercent}" />
              </div>
              <div class="slider__info">
                <div class="slider__info__block">
                  <span>Supporter</span>
                  <span>5 Referrals</span>
                  <span>Get $25</span>
                </div>
                <div class="slider__info__block">
                  <span>Ambassador</span>
                  <span>20 Referrals</span>
                  <span>Get $100</span>
                </div>
                <div class="slider__info__block">
                  <span>VIP</span>
                  <span>50 Referrals</span>
                  <span>Get $250</span>
                </div>
              </div>
            </div>
            <div v-if="referralUrl" class="referral-settings__link">
              <base-input :value="referralUrl" type="text" :focus="false" font-size=""
                          readonly placeholder="Copy and paste the url below into Twitter, Facebook or an Email."
              />
              <button class="referral-settings__link__copy" @click="copy">
                Copy
              </button>
            </div>
            <div class="referral-settings__send">
              <base-input v-model="emailList" value="emailList" type="email" font-size=""
                          placeholder="Invite friends via Email:"
              />
              <button class="referral-settings__send__button" @click="sendReferralEmail">
                Invite
              </button>
              <div v-if="emailedList && emailedList.length" class="emailed-list">
                Thank you! An invitation email has been sent to: <span class="weight-400">{{ emailedList.join(', ') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Modal from '@/components/Modal'
import copy from 'copy-text-to-clipboard'
import BaseInput from '../../blocks/Form/BaseInput'

export default {
  components: {
    BaseInput,
    Modal
  },
  data () {
    return {
      emailList: '',
      emailedList: []
    }
  },
  beforeDestroy () {
  },
  computed: {
    ...mapGetters({
      'info': 'referral/inviteInfo',
      'email': 'referral/invitedEmail'
    }),
    currentReferrals () {
      if (this.info && (this.info.inviteCount || +this.info.inviteCount === 0)) {
        return +this.info.inviteCount
      }
      return 0
    },
    progressPercent () {
      if (this.currentReferrals <= 5) {
        return this.currentReferrals * (17 / 5) + '%'
      }
      if (this.currentReferrals > 5 && this.currentReferrals <= 20) {
        return 17 + (this.currentReferrals - 5) * ((41 - 17) / 15) + '%'
      }
      return (this.currentReferrals <= 50 ? this.currentReferrals : 50) * 2 + '%'
    },
    baseUrl () {
      if (typeof window !== 'undefined') {
        return window.location.origin
      }
      return 'localhost'
    },
    referralUrl () {
      if (this.info) {
        return this.baseUrl + '/invite/' + this.info._id
      }
      return ''
    }
  },
  methods: {
    ...mapActions('referral', ['sendInviteEmail']),
    close () {
      // TODO Move to theme
      this.$bus.$emit('modal-hide', 'modal-referral')
    },
    copy () {
      // TODO Move to theme
      copy(this.referralUrl)
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: 'Url copied to yor clipboard',
        action1: { label: 'OK' }
      })
    },
    sendReferralEmail () {
      const friends = this.emailList.split(/[\s,]+/)
      if (this.info && friends) {
        this.sendInviteEmail(friends).then(list => {
          this.emailedList = list
          this.emailList = ''
        })
      }
    }
  },
  watch: {
    'user': 'updateInfo'
  }
}
</script>

<style lang="scss" scoped>
$slider-border: color(primary, $colors-border);
$color-white: color(primary, $colors-background);
$slider-progress: color(success, $colors-theme);
$white: color(white);

.question {
  &__single-button {
    width: 100%;
    display: block;
    padding: 20px 15%;
    border: 3px solid $slider-border;
    font-size: 16px;
    background-color: $white;
    cursor: pointer;
    outline: none;
    margin-bottom: 10px;
  }
}
.emailed-list {
  margin: 20px 0;
  font-size: 14px;
}
.referral-settings {
  &__info {
    margin: 10px 0;
    font-size: 14px;
    span {
      font-weight: bold;
    }
  }
  &__link {
    margin: 40px 0;
    position: relative;
    & > input[type=text] {
      font-size: 12px !important;
    }
    &__copy {
      position: absolute;
      right: 5px;
      top: 8px;
      border-radius: 3px;
      border: none;
      background-color: $slider-progress;
      color: $color-white;
      padding: 5px 10px;
      margin-bottom: 2px;
      box-sizing: border-box;
    }
  }
  &__send {
    position: relative;
    margin: 20px 0;
    &__button {
      position: absolute;
      right: 5px;
      top: 8px;
      border-radius: 3px;
      border: none;
      background-color: $slider-progress;
      color: $color-white;
      padding: 5px 10px;
      margin-bottom: 2px;
      box-sizing: border-box;
    }
  }
  .slider {
    &__base {
      height: 12px;
      border-radius: 2px;
      border: 1px solid $slider-border;
      padding: 1px;
      box-sizing: border-box;
    }

    &__progress {
      height: 8px;
      background-color: $slider-progress;
      min-width: 0.5%;
      transition: all 3s ease-out;
    }

    &__info {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 4fr 10fr;
      padding: 1px;
      box-sizing: border-box;
      margin-bottom: 20px;

      &__block {
        box-sizing: border-box;
        padding: 10px 10px 10px;
        text-align: right;
        font-size: 14px;
        border-right: 1px solid $slider-border;

        & > span {
          display: block;
          font-size: 12px;

          &:first-of-type {
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
