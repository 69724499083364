<template>
  <button
    type="button"
    class="bg-cl-transparent brdr-none inline-flex"
    @click="openSidebarMenu"
    :aria-label="'Open menu'"
    data-testid="menuButton"
  >
    <i class="material-icons">dehaze</i>
  </button>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState({
    isOpen: state => state.ui.sidebar
  }),
  methods: {
    openSidebarMenu () {
      this.$store.commit('ui/setSidebar', !this.isOpen)
    }
  }
}
</script>
