<template>
  <div class="header">
    <header
      class="fixed w-100 brdr-bottom-1 bg-cl-primary brdr-cl-secondary"
      :class="{ 'is-visible': navVisible }"
    >
      <div class="container px15">
        <div class="row between-xs middle-xs" v-if="!isCheckoutPage">
          <div class="col-md-4 col-xs-2 middle-xs">
            <div>
              <template v-if="!canGoBack">
                <hamburger-icon class="p15 icon bg-cl-secondary pointer" v-if="!canGoBack" />
              </template>
              <template v-else>
                <return-icon class="p15 icon bg-cl-secondary pointer" v-if="canGoBack" />
              </template>
            </div>
          </div>
          <!--<div class="col-xs-2 visible-xs">-->
          <!--<search-icon class="p15 icon pointer" />-->
          <!--</div>-->
          <div class="col-md-4 col-xs-4 center-xs pt5">
            <div>
              <logo width="auto" height="40px" invert />
            </div>
          </div>
          <!--<div class="col-xs-2 visible-xs">-->
          <!--<wishlist-icon class="p15 icon pointer" />-->
          <!--</div>-->
          <div class="col-md-4 col-xs-2 end-xs">
            <div class="inline-flex right-icons">
              <!--<search-icon class="p15 icon hidden-xs pointer" />-->
              <!--<wishlist-icon class="p15 icon hidden-xs pointer" />-->
              <!--<compare-icon class="p15 icon hidden-xs pointer" />-->
              <microcart-icon class="p15 icon pointer" />
              <account-icon class="p15 icon hidden-xs pointer" />
              <signup-button v-if="!currentUser" class="p15 icon hidden-xs pointer" />
            </div>
          </div>
        </div>
        <div class="row between-xs middle-xs px15 py5" v-if="isCheckoutPage">
          <div class="col-xs-5 col-md-3 middle-xs">
            <div>
              <router-link :to="backLink" class="cl-tertiary links">
                Return to shopping
              </router-link>
            </div>
          </div>
          <div class="col-xs-2 col-md-6 center-xs">
            <logo width="auto" height="41px" />
          </div>
          <div class="col-xs-5 col-md-3 end-xs">
            <div>
              <a v-if="!currentUser" href="#" @click.prevent="gotoAccount" class="cl-tertiary links">
                Login to your account
              </a>
              <span v-else>
                You are logged in as {{ currentUser.firstname }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="header-placeholder" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CurrentPage from '../../../mixins/currentPage'
import Logo from './../../Logo'
import AccountIcon from './AccountIcon'
import HamburgerIcon from './HamburgerIcon'
import MicrocartIcon from './MicrocartIcon'
import ReturnIcon from './ReturnIcon'
import SignupButton from './SignupButton'

export default {
  name: 'Header',
  components: {
    AccountIcon,
    HamburgerIcon,
    Logo,
    ReturnIcon,
    MicrocartIcon,
    SignupButton
  },
  mixins: [CurrentPage],
  data () {
    return {
      navVisible: true,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      navbarHeight: 54
    }
  },
  computed: {
    ...mapState({
      isOpenLogin: state => state.ui.signUp,
      currentUser: state => state.user.current
    }),
    backLink () {
      return this.$store.getters['user/isLoggedIn'] && this.$store.getters['questionnaire/canCreateOffers'] ? '/questionnaire/offers' : '/questionnaire'
    }
  },
  beforeMount () {
    window.addEventListener('scroll', () => {
      this.isScrolling = true
    }, { passive: true })

    setInterval(() => {
      if (this.isScrolling) {
        this.hasScrolled()
        this.isScrolling = false
      }
    }, 250)
  },
  methods: {
    gotoAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup')
    },
    hasScrolled () {
      this.scrollTop = window.scrollY
      if (this.scrollTop > this.lastScrollTop && this.scrollTop > this.navbarHeight) {
        this.navVisible = false
      } else {
        this.navVisible = true
      }
      this.lastScrollTop = this.scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
$color-icon-hover: color(secondary, $colors-background);

header {
  height: 54px;
  top: -55px;
  z-index: 2;
  transition: top 0.2s ease-in-out;
  &.is-visible {
    top: 0;
  }
}
.icon {
  opacity: 0.6;
  &:hover,
  &:focus {
    background-color: $color-icon-hover;
    opacity: 1;
  }
}
.right-icons {
  //for edge
  float: right;
}
.header-placeholder {
  height: 54px;
}
@media (max-width: 767px) {
  .row.middle-xs {
    margin: 0 -15px;

    &.py5 {
      margin: 0;
    }
  }
  .col-xs-2:first-of-type {
      padding-left: 0;
  }
  .col-xs-2:last-of-type {
      padding-right: 0;
  }
  a, span {
    font-size: 12px;
  }
}
</style>
