<template>
  <transition name="fade" appear>
    <div class="cookie fixed w-100 bg-cl-th-accent cl-tertiary" v-if="isOpen">
      <div class="container">
        <div class="row between-xs middle-xs px15">
          <div class="col-xs-10 start-xs">
            <span class="pr5">
              {{ message }}
            </span>
            <router-link to="/privacy" :title="detailsLinkText" class="cl-bg-tertiary">
              {{ detailsLinkText }}
            </router-link>
          </div>
          <div class="col-xs-2 end-xs">
            <i
              class="material-icons icon p15 pointer"
              @click="accept"
              data-testid="closeCookieButton"
            >
              close
            </i>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Cookie from 'js-cookie'
export default {
  props: {
    detailsLinkText: {
      type: String,
      default: 'See details'
    },
    detailsLink: {
      type: String,
      default: '/privacy'
    },
    message: {
      type: String,
      default: 'We use cookies to give you the best shopping experience.'
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    accept () {
      this.setVisited()
      this.isOpen = false
    },
    setVisited () {
      Cookie.set('cookiesAccepted', '1')
    }
  },
  created () {
    const accepted = Cookie.get('cookiesAccepted')
    if (+accepted !== 1) {
      this.isOpen = true
      Cookie.set('cookiesAccepted', '0')
    } else {
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
$color-icon: color(black);
$bg-icon: color(suva-gray);

.cookie {
  z-index: 2;
  bottom: 0;
}

.icon:hover {
  color: $color-icon;
  background-color: $bg-icon;
}
</style>
