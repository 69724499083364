<template>
  <modal name="schedule-call" :width="700" height="auto" scrollable @before-open="handleOpen" @before-close="handleClose">
    <div class="modal-window__close-button" @click="close" />
    <div class="modal-window__caption -draggable">
      Schedule A Call
    </div>
    <div class="modal-window__content" :class="{'-loading': loading}" v-if="open">
      <no-ssr>
        <time-slots v-show="!loading" :minute-range="15" :min-hour="10"
                    :max-hour="17" v-model="date"
                    :booked-slots="busySchedulePeriods"
        />
      </no-ssr>
    </div>

    <div class="modal-window__actions">
      <button-full class="mb20 mt10" @click="scheduleACall" :disabled="!date || !isValidDate || loading">
        Apply
      </button-full>
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import NoSsr from 'vue-no-ssr'
// import TimePicker from 'vue-ctk-date-time-picker'
import TimeSlots from './partials/TimeSlotSelector'
import { required, email, alphaNum } from 'vuelidate/lib/validators'
import { trackEvent } from '../../../utils/analytics'

export default {
  name: 'ScheduleACall',
  validations: {
    email: {
      required,
      email
    },
    firstName: {
      required
    },
    lastName: {
      required
    },
    purpose: {
      required
    },
    phone: {
      alphaNum,
      required
    }
  },
  components: {
    NoSsr,
    // TimePicker,
    TimeSlots
  },
  data () {
    return {
      date: null,
      loading: false,
      open: false,
      firstName: '',
      lastName: '',
      purpose: '',
      phone: '',
      disabledDates: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '18', '19', '20', '21', '22', '23'],
      disabledWeekdays: ['0', '6']
    }
  },
  computed: {
    ...mapState({
      activeElem: state => state.ui.authElem
    }),
    ...mapGetters('referral', ['busySchedule']),
    isValidDate () {
      const date = moment(this.date)
      return date.isValid() && !this.disabledDates.find(h => +h === date.hours()) && !this.busySchedulePeriods.find(s => s(moment(this.date)))
    },
    busySchedulePeriods () {
      return this.busySchedule ? this.busySchedule.map(({ start, end }) => {
        const startMoment = moment(start)
        const endMoment = moment(end)
        return checkDate => {
          return checkDate.isBetween(startMoment, endMoment)
        }
      }) : []
    },
    currentDisabledHours () {
      const allHours = Array.from(new Array(24), (_, i) => `${i <= 9 ? '0' : ''}${i}`)
      const selectedDateMoment = moment(this.date)
      return allHours.filter(hour => {
        if (this.disabledDates.includes(hour)) return true
        const hourMoment = selectedDateMoment.clone().hours(+hour)
        return selectedDateMoment.isValid() && this.busySchedulePeriods.find(s => s(hourMoment))
      })
    }
  },
  watch: {
    date (value) {
      this.$store.commit('referral/setScheduledDate', value)
    }
  },
  methods: {
    ...mapActions('referral', ['getBusySchedule']),
    handleOpen () {
      this.open = true
      this.getBusySchedule()
    },
    handleClose () {
      this.open = false
      if (this.$route.path === '/consultation/schedule') {
        this.$router.push('/')
      }
    },
    getMinDate () {
      const date = new Date()
      date.setDate(date.getDate() - 1)
      return date.toUTCString()
    },
    close () {
      // TODO Move to theme
      this.$modal.hide('schedule-call')
    },
    scheduleACall () {
      this.loading = true
      this.$store.dispatch('referral/setScheduledDate').then(() => {
        this.close()
        this.loading = false
        trackEvent('user-action', 'consultation-call-scheduled')
        const scheduledTime = moment(this.date)
        this.$modal.show('dialog', {
          title: 'Appointment',
          text: `Thank you for scheduling your design consultation, we
           look forward to speaking with you at ${scheduledTime.format('LT')} on ${scheduledTime.format('LL')}`,
          buttons: [
            {
              title: 'Ok',
              class: 'accent',
              handler: () => {
                this.date = null
                this.$modal.hide('dialog')
              }
            },
            {
              class: 'close',
              title: 'Close'
            }
          ]
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
  .modal {
    font-size: 18px;
  }
  .modal-window__content {
    position: relative;
    @include smaller-than(tablet) {
      max-height: 85vh;
      overflow: auto;
    }
    &.-loading {
      &:before {
        content: '';
        z-index: 10;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-image: url(../../../assets/loading.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
      }
    }
  }
</style>
