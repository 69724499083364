<template>
  <div class="inline-flex relative dropdown"
       data-testid="accountButton"
       @click.self="goToAccount"
  >
    <button
      type="button"
      class="bg-cl-transparent brdr-none p0 fs-medium-small"
      :aria-label="'Open my account'"
    >
      {{ currentUser ? 'Account' : 'Login' }}
    </button>
    <div v-if="currentUser" class="dropdown-content bg-cl-primary align-left sans-serif lh20 weight-400">
      <div class="py5">
        <div v-for="(page, index) in shownNavigation" :key="index" @click="notify(page.title)">
          <router-link class="no-underline block py10 px15" :to="page.link">
            {{ page.title }}
          </router-link>
        </div>
      </div>
      <div class="py5 brdr-top-1 brdr-cl-bg-secondary">
        <a href="#" class="no-underline block py10 px15" @click.prevent="logout">
          Logout
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      navigation: [
        { title: 'My profile', link: '/my-account' },
        { title: 'My shipping details', link: '/my-account/shipping-details' },
        { title: 'My newsletter', link: '/my-account/newsletter' },
        { title: 'My orders', link: '/my-account/orders' },
        { title: 'My referral program', link: '/my-account/referral' },
        { title: 'My personal designs', link: '/questionnaire/offers' }
        // { title: 'My questionnaire', link: '/my-account/questionnaire' },
        // { title: 'My Recently viewed products', link: '/my-account/recently-viewed' }
      ]
    }
  },
  name: 'AccountIcon',
  computed: {
    currentUser () {
      // renamed to 'user'
      return this.user
    },
    isLoggedIn () {
      return this.$store.getters['user/isLoggedIn']
    },
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user.current
      }
      return null
    },
    shownNavigation () {
      return this.navigation.filter(l => {
        return l.title !== 'My personal designs' || this.$store.getters['questionnaire/canCreateOffers']
      })
    }
  },
  methods: {
    notify (title) {
      if (title === 'My loyalty card' || title === 'My product reviews') {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: 'This feature is not implemented yet! Please take a look at https://github.com/DivanteLtd/vue-storefront/issues for our Roadmap!',
          action1: { label: 'OK' }
        })
      }
    },
    goToAccount () {
      if (this.currentUser) {
        this.$router.push('/my-account')
      } else {
        this.$store.commit('ui/setAuthElem', 'login')
        this.$bus.$emit('modal-show', 'modal-signup')
      }
    },
    logout () {
      this.$store.dispatch('user/logout', { silent: false })
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../css/base/global_vars';
$color-icon-hover: color(secondary, $colors-background);

.dropdown {

  button {
    pointer-events: none;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    width: 160px;
    z-index: 1;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }

  a {
    opacity: .6;

    &:hover,
    &:focus {
      background-color: $color-icon-hover;
      opacity: 1;
    }

  }

  @media (min-width: 768px) {
    &:hover .dropdown-content {
      display: block;
    }
  }

}
</style>
